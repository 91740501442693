import { Typography } from 'antd'

const { Text } = Typography

export const plansPrices = {
  basic_monthly_15: 29,
  basic_monthly_25: 63,
  basic_monthly_40: 100,

  basic_annual_15: 23,
  basic_annual_25: 50,
  basic_annual_40: 80,

  advanced_annual_100: 59,
}

export const plansPricesAnnually = {
  basic_15: 278,
  basic_25: 600,
  basic_40: 960,

  advanced_200: 4224,
}

export const minutesBasic = [15, 25, 40]

export const minutesAdvanced = [100]

export const plansData = [
  [
    {
      key: 0,
      name: '',
      basic: <Text type="secondary">Basic</Text>,
      advanced: <Text type="secondary">Advanced</Text>,
      custom: <Text type="secondary">Custom</Text>,
    },
  ],
  [
    {
      key: 4,
      name: <Text type="secondary">Limits</Text>,
      onCell: { colSpan: 0 },
    },
    {
      key: 'minutes',
      name: 'Minutes/Month',
      basic: 'basic',
      advanced: 'advanced',
      custom: 'custom',
    },

    {
      key: 6,
      name: 'Avatars',
      basic: '80+',
      advanced: '80+',
      custom: '80+',
    },
    {
      key: 7,
      name: 'Seats',
      basic: 1,
      advanced: 3,
      custom: 'Unlimited',
    },
    {
      key: 8,
      name: '1080p Full HD videos',
      basic: true,
      advanced: true,
      custom: true,
    },
    {
      key: 9,
      name: '4K Ultra HD videos',
      basic: false,
      advanced: true,
      custom: true,
    },
    {
      key: 10,
      name: 'Vertical and square videos',
      basic: true,
      advanced: true,
      custom: true,
    },
  ],
  [
    {
      key: 11,
      name: <Text type="secondary">Media</Text>,
      onCell: { colSpan: 0 },
    },
    {
      key: 12,
      name: 'Stock videos and images',
      basic: true,
      advanced: true,
      custom: true,
    },
    {
      key: 13,
      name: 'Stock Lottie animations',
      basic: true,
      advanced: true,
      custom: true,
    },
    {
      key: 14,
      name: 'Stock gifs and stickers',
      basic: true,
      advanced: true,
      custom: true,
    },
    {
      key: 15,
      name: 'Stock music',
      basic: true,
      advanced: true,
      custom: true,
    },
  ],
  [
    {
      key: 16,
      name: <Text type="secondary">Features</Text>,
      onCell: { colSpan: 0 },
    },
    {
      key: 17,
      name: '100+ languages',
      basic: true,
      advanced: true,
      custom: true,
    },
    {
      key: 18,
      name: '450+ voices',
      basic: true,
      advanced: true,
      custom: true,
    },
    {
      key: 19,
      name: '100+ templates',
      basic: true,
      advanced: true,
      custom: true,
    },
    {
      key: 20,
      name: 'Transitions',
      basic: true,
      advanced: true,
      custom: true,
    },
    {
      key: 22,
      name: 'Animations',
      basic: true,
      advanced: true,
      custom: true,
    },
    {
      key: 23,
      name: 'Custom template',
      basic: true,
      advanced: true,
      custom: true,
    },
    {
      key: 24,
      name: 'Share video',
      basic: true,
      advanced: true,
      custom: true,
    },
    {
      key: 25,
      name: 'Import PPTX and PDF',
      basic: true,
      advanced: true,
      custom: true,
    },
    {
      key: 26,
      name: 'Voiceover upload',
      basic: true,
      advanced: true,
      custom: true,
    },
    {
      key: 27,
      name: 'Auto captions / subtitles',
      basic: true,
      advanced: true,
      custom: true,
    },
    {
      key: 28,
      name: 'Public API',
      basic: true,
      advanced: true,
      custom: true,
    },
    {
      key: 29,
      name: 'Automatic translation',
      basic: true,
      advanced: true,
      custom: true,
    },
    {
      key: 30,
      name: 'URL to video',
      basic: true,
      advanced: true,
      custom: true,
    },
    {
      key: 31,
      name: 'Custom music upload',
      basic: false,
      advanced: true,
      custom: true,
    },
    {
      key: 32,
      name: 'Custom fonts upload',
      basic: false,
      advanced: true,
      custom: true,
    },
    {
      key: 33,
      name: 'Premium voices',
      basic: false,
      advanced: true,
      custom: true,
    },
    {
      key: 34,
      name: 'AI Storyboard',
      basic: true,
      advanced: true,
      custom: true,
    },
    {
      key: 35,
      name: 'Photo avatars',
      basic: true,
      advanced: true,
      custom: true,
    },
    {
      key: 36,
      name: 'Build-in screen recorder',
      basic: true,
      advanced: true,
      custom: true,
    },
    {
      key: 37,
      name: 'Single sign-on',
      basic: false,
      advanced: false,
      custom: true,
    },
    {
      key: 38,
      name: 'Workspaces/collaboration',
      basic: false,
      advanced: false,
      custom: true,
    },
  ],
]
